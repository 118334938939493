import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import "./assets/scss/style.scss";
import Spinner from "./views/spinner/Spinner";
import ErrorBoundary from "./erro";
import { SnackbarProvider } from 'notistack';

// setup fake backend
import { ConfigureFakeBackend } from "./jwt/_helpers";


ConfigureFakeBackend();
const App = lazy(() => import("./app"))

ReactDOM.render(
  <Suspense fallback={<Spinner />}>
    <ErrorBoundary>
      {(hasError) => (
        <SnackbarProvider maxSnack={6}>
          <App hasError={hasError} />
        </SnackbarProvider>
      )}
    </ErrorBoundary>
  </Suspense>,
  document.getElementById("root")
);
