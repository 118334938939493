import firebase from "firebase/app"
import "firebase/auth"
import "firebase/database"
import "firebase/firestore"
import "firebase/functions"
import '@firebase/storage'

var config = {
  apiKey: "AIzaSyDVGqbRyZ5Fbw2ILuHu9wI5SnEW-f9NENU",
  authDomain: "joaoerp-f66a7.firebaseapp.com",
  databaseURL: "https://joaoerp-f66a7.firebaseio.com",
  projectId: "joaoerp-f66a7",
  storageBucket: "joaoerp-f66a7.appspot.com",
  messagingSenderId: "295548370353",
  appId: "1:295548370353:web:863f706474cb1f98fe5190",
  measurementId: "G-DJRD4V03VW"
};

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const Db = firebase.database()
const Auth = firebase.auth()
const Fb = firebase.firestore()
const St = firebase.storage()
const functions = firebase.functions()

const location = window.location

if (location.hostname === "localhost") {
  functions.useFunctionsEmulator('http://localhost:5001');
}

export { Db, Auth, Fb, St, functions };
