import { Auth, Fb } from "./Firebase";

// Sign Up
export const doCreateUserWithEmailAndPassword = (email, password) =>
  Auth.createUserWithEmailAndPassword(email, password);

// Sign In
export const doSignInWithEmailAndPassword = (email, password) => {
  return Auth.signInWithEmailAndPassword(email, password)
}

// Sign out
export const doSignOut = () => Auth.signOut();
// Password Reset
export const doPasswordReset = (email) => Auth.sendPasswordResetEmail(email);

// Password Change
export const doPasswordUpdate = (password) =>
  Auth.currentUser.updatePassword(password);

export async function setUser(userId) {
  // função para salvar os dados do usuário no local storage
  return Fb.collection('users')
    .doc(userId)
    .onSnapshot((r) => {
      const user = { id: r.id, data: r.data() }
      localStorage.setItem('user', JSON.stringify(user))
      return user
    })
}

export async function setGrupoEstabelecimentos() {
  // função para salvar em local storage qual grupo de empresas esse usuário trabalha
  // utilizado para salvar no cadastro dos clientes e veículos
  const user = await getUser()
  const a = await Fb.collection('estabelecimentos_grupos')
    .doc(user.data.fk_estabelecimentos_grupo)
    .onSnapshot((querySnapshot) => {
      const grupo = { id: querySnapshot.id, data: querySnapshot.data() }
      localStorage.setItem('grupo', JSON.stringify(grupo))
      return grupo
    })
  return a
}

export async function setEstabelecimentosUsuario() {
  // função para salvar em local storage as empresas esse usuário trabalha
  // utilizado para salvar os atendimentos
  const user = await getUser()
  const a = await Fb.collection('estabelecimentos')
    .where('fk_estabelecimentos_grupo', '==', user.data.fk_estabelecimentos_grupo)
    .where('fk_users', 'array-contains', user.id)
    .onSnapshot((r) => {
      const currEstabelecimentosUsuario = localStorage.getItem('estabelecimentosUsuario')
      let arr = currEstabelecimentosUsuario ? JSON.parse(currEstabelecimentosUsuario) : []
      r.docChanges().forEach((change) => {
        let item = { id: change.doc.id, data: change.doc.data() }
        if (item.id === user.data.estabelecimento_preferencial) {
          item.preferencial = true
        } else {
          item.preferencial = false
        }
        if (change.type === "added") {
          arr.push(item)
        }
        else if (change.type === "modified") {
          const pos = arr.map((e) => e.id).indexOf(item.id)
          arr[pos] = item
        }
        else if (change.type === "removed") {
          arr = arr.filter(f => f.id !== item.id)
        }
      })
      arr.sort(function (a, b) {
        return a.estabelecimento < b.estabelecimento ? -1 : a.estabelecimento > b.estabelecimento ? 1 : 0
      });
      arr.sort(function (a, b) {
        return (a.preferencial === b.preferencial) ? 0 : a.preferencial ? -1 : 1;
      })
      arr = [...new Set(arr)]
      const estIds = arr.map(e => e.id)
      const uniqueIds = [...new Set(estIds)]
      arr = uniqueIds.map(u => u = arr.filter(f => f.id === u)[0])
      localStorage.setItem('estabelecimentosUsuario', JSON.stringify(arr))
      return arr
    })
  return a
}

async function getAtendimentosStatus() {
  Fb.collection('atendimentos_status')
    .get()
    .then((r) => {
      let arr = []
      r.forEach((doc) => {
        arr.push({ id: doc.id, data: doc.data() })
      })
      arr.sort(function (a, b) {
        return (a.data.ordem === b.data.ordem) ? 0 : a.data.ordem < b.data.ordem ? -1 : 1;
      })
      localStorage.setItem('atendimentosStatus', JSON.stringify(arr))
    })
}

async function getUser() {
  // pega do local storage os dados do banco do usuário logado
  if (!localStorage.getItem('user')) {
    await timeout(100)
    return getUser()
  } else {
    return JSON.parse(localStorage.getItem('user'))
  }
}

function timeout(ms) {
  return new Promise(resp => {
    setTimeout(resp, ms)
  })
}

async function verificar() {
  const user = JSON.parse(localStorage.getItem('user'))
  const grupo = JSON.parse(localStorage.getItem('grupo'))
  const estabelecimentos = JSON.parse(localStorage.getItem('estabelecimentosUsuario'))
  if (!user || !grupo || !estabelecimentos) {
    await timeout(100)
    return verificar()
  } else {
    return true
  }
}

export const authProvider = async (userid) => {

  const firstAccess = sessionStorage.getItem('firstAccess')

  if (!firstAccess) {
    sessionStorage.setItem('firstAccess', true)
    const r = await Promise.all([
      setUser(userid),
      setGrupoEstabelecimentos(),
      setEstabelecimentosUsuario(),
      getAtendimentosStatus()
    ])
    await verificar()
    return r;
  }
}

export const setAll = () => {
  const user = JSON.parse(localStorage.getItem('user'))
  Promise.all([
    setUser(user.id),
    setGrupoEstabelecimentos(),
    setEstabelecimentosUsuario()
  ])
}
