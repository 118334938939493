import { doSignInWithEmailAndPassword } from "../../firebase/Auth";


export function ConfigureFakeBackend() {

  let users = [
    {
      id: 1,
      username: "test",
      password: "test",
      firstName: "Test",
      lastName: "User",
    },
  ];
  let realFetch = window.fetch;
  window.fetch = function (url, opts) {
    const isLoggedIn =
      opts.headers["Authorization"] === "Bearer fake-jwt-token";

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        // authenticate - public
        if (url.endsWith("/users/authenticate") && opts.method === "POST") {
          const params = JSON.parse(opts.body);

          return doSignInWithEmailAndPassword(params.username, params.password)
            .then((r) => {
              if (!r.user) return error("Usuário ou E-mail inválidos");
              return ok({
                id: r.user.uid,
                username: r.user.displayName,
                firstName: r.user.displayName ? r.user.displayName.split(' ')[0] : '',
                lastName: r.user.displayName ? r.user.displayName.split(' ')[0] : '',
                token: r.user.uid
              });
            }).catch(() => {
              return error("Usuário ou Senha inválidos");
            })
        }

        // get users - secure
        if (url.endsWith("/users") && opts.method === "GET") {
          if (!isLoggedIn) return unauthorised();
          return ok(users);
        }

        // pass through any requests not handled above
        realFetch(url, opts).then((response) => resolve(response));

        // private helper functions

        function ok(body) {
          resolve({
            ok: true,
            text: () => Promise.resolve(JSON.stringify(body)),
          });
        }

        function unauthorised() {
          resolve({
            status: 401,
            text: () =>
              Promise.resolve(JSON.stringify({ message: "Unauthorised" })),
          });
        }

        function error(message) {
          resolve({
            status: 400,
            text: () => Promise.resolve(JSON.stringify({ message })),
          });
        }
      }, 0);
    });
  };
}
